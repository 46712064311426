import React from 'react'
import AtlantaTemplate from '../../../templates/AtlantaTemplate'

const AtlantaIndex = () => {

    return (
        <AtlantaTemplate>
            <div className="salesforce-footer"></div>

            <section id="welcome">
                <div className="content-width position-relative">
                <img className="logo logoHome" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/2023-Bootcamp-Seal_Atlanta_Color.png" />
                <h2>Join us for the ultimate in-person learning experience.</h2>
                <h1>Trailblazer Bootcamp</h1>
                <p>April 30 - May 5 &nbsp;|&nbsp; Atlanta, GA</p>
                <p>*EARLY BIRD &nbsp;|&nbsp; $2,599 until 11:59 PM EST on March 15, 2023 &nbsp;|&nbsp; FULL PRICE $2,999</p>
                <p>*Accommodations are not included in the price of the Trailblazer Bootcamp pass and may be purchased separately through the registration process.</p>
                <p className="ptop"><a className="reg-btn btn" target="_blank" href="https://bcatlanta.cventevents.com/dQrnMm">Register Now</a></p>
                </div>
            </section>

            <section id="explore">
                <div className="content-width">
                <h1>CALLING ALL TRAILBLAZERS</h1>
                <h2>Pack your bags for Trailblazer Bootcamp.</h2>
                <p>
                    With six days of expert-led learning, 10 role-based tracks, two onsite certification exams, and endless networking opportunities, Trailblazer Bootcamp is the place to build expertise and accelerate your path to certification.
                </p>
                <p><a className="link-btn btn" href="/bootcamps/atlanta/tracks">Explore Tracks</a></p>
                </div>
            </section>

            <section id="reasons">
                <div className="content-width" id="reasons">
                <div className="reason">
                    <div className="r-3">
                        <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/Learn-Icon.png" alt="Learn" />
                        <p>Learn</p>
                    </div>
                    <div className="reason-text">Build your skill set and learn directly from experts.</div>
                </div>
                <div className="reason">
                    <div className="r-2">
                        <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/Earn-Icon.png" alt="Earn" />
                        <p>Earn</p>
                    </div>
                    <div className="reason-text">Accelerate your path to certification with onsite certification exams.</div>
                </div>
                <div className="reason">
                    <div className="r-1">
                        <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/Connect-Icon.png" alt="Connect" />
                        <p>Connect</p>
                    </div>
                    <div className="reason-text">Meet fellow Trailblazers, share knowledge, and build your network.</div>
                </div>
                <div className="reason">
                    <div className="r-4">
                        <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/GiveBack-Icon.png" alt="Give Back" />
                        <p>Give Back</p>
                    </div>
                    <div className="reason-text">Join in as we give back to the local community.</div>
                </div>
                </div>
            </section>

            <section id="quote">
                <div className="content-width">
                <div className="quote-holder">			
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/quote.png" className="quote-img"></img>
                </div>
                </div>
            </section>

            <section id="home-footer">
                <div className="register-msg">
                <p><a className="reg-btn btn" target="_blank" href="https://bcatlanta.cventevents.com/dQrnMm">Register Now</a></p>
                </div>
            </section>

            <div className="characters home-footer">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/Home.png" />
            </div>
        </AtlantaTemplate>
    )
}

export default AtlantaIndex
